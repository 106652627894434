.jh-header-container{
  position: fixed;
  top:0px;
  width:100%;
  background-color:#fff;
  z-index: 1000;
}
.jh-header-flex{
  display:flex;
  flex-direction: row;
  padding:10px;
  justify-content: space-between;
}
.jh-header-logo-container{
  display:flex;
  flex-direction: row;
  align-items: center;
}
.jh-header-beta{
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
}
.jh-header-logo{
  height:60px;
  width:200px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.jh-header-buttons-container{
  display:flex;
  flex-direction: row;
  align-items: center;
}
.jh-header-buttons-container>div{
  padding:0 10px 0 10px;
}
@media screen and (max-width:786px){
  .jh-header-logo-container{
    display:none;
  }
}
