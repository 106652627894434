.jh-resume-list-container{
  padding-top:100px;
  width:100%;
}
.jh-resume-list-heading{
  font-size:60px;
  padding:40px;
  color:#222;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
}
.jh-resume-list{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.jh-resume-list-item{
  background-color:#fff;
  padding:30px;
  font-size:20px;
  font-weight: bold;
  border:6px solid #fff;
  transition: all linear 0.2s;
  margin-top:20px;
  width:300px;
}
.jh-resume-list-item:hover{
  background-color:#ddd;
  transition: all linear 0.2s;
}
.jh-resume-list-item>h2{
  font-size:20px;
  color:#333;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.jh-resume-list-item>p{
  font-size:14px;
  padding-top:6px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  color:#999;
}
.jh-create-resume-button-container{
  padding:20px;
}

.jh-edit-form-close{
  position:fixed;
  font-weight: lighter;
  top:25px;
  right:20px;
  font-size:50px;
  cursor: pointer;
  color:#aaa;
  transition: all linear 0.2s;
  z-index: 1000;
  width: 50px;
  height: 50px;
  border: 2px solid #aaa;
  text-align: center;
  border-radius: 50%;
}
.jh-edit-form-close:hover{
  color:#666;
  border: 2px solid #666;
  border-radius: 25%;
  transition: all linear 0.2s;
}

.jh-create-resume-form-container{
  /* width:100%; */
}
.jh-resume-first-container{
  height:100vh;
  width:100%;
}
.jh-resume-first-flex{
  height:100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.jh-resume-first-heading{
  font-size: 60px;
  padding: 30px 0;
  width: 100%;
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  font-weight: lighter;
  top: 0;
  background-color: #fff;
  text-transform: uppercase;
  z-index: 900;
  max-width:800px;
}
.jh-resume-first-subtitle{
  color: #999;
  padding-bottom: 40px;
}
