button, input[type=submit], input[type=reset] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  opacity:1;
  outline: inherit;
  transition: all linear 0.2s;
}

button:disabled {
  opacity: 0.6;
  transition: all linear 0.2s;
  cursor: not-allowed;
}

input[type=text],input[type=number],input[type=email],input[type=password],input[type=date]{
  background:none;
  font-size:20px;
  width:100%;
  padding-left:0px;
  padding-right:0px;
  padding-top:6px;
  padding-bottom:6px;
  border:none;
  line-height: normal;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
label{
  /* margin:20px;
  width:100%; */
}
label>h2{
  text-transform: uppercase;
  /* font-weight: bold; */
}
.jh-enter-form-container{
  height:100vh;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image:url('../../images/writing-resume.jpg'),url('../../images/writing-resume-small.jpg');
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.jh-enter-form-heading{
  font-size: 60px;
  padding: 30px 0px 30px 0px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.jh-enter-form{
  display:flex;
  width:40%;
  max-width:400px;
  margin:auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background:#fff;
  border-radius: 20px;
  padding:20px;
}

.jh-form-label{
  border:2px solid #fff;
  transition: all linear 0.2s;
  display:block;
  padding:10px;
  margin-top:10px;
  margin-bottom:10px;
}
.jh-form-label:hover{
  transition: all linear 0.2s;
  border:2px solid #ddd;
  border-radius: 10px;
}
.jh-form-label>h2{
  padding-top:20px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.jh-sticky-form-subsection{
  position: sticky;
  top:120px;
  padding:20px;
  color:#fff;
  background:#222;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.jh-edit-form-container{
  min-height:100vh;
  width:100%;
  font-family: sans-serif;
  /* padding-top:100px;
  padding-bottom:100px; */
  display:flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color:#fff;
}
.jh-edit-form{
  display:flex;
  width:50%;
  /* margin:auto; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}
.jh-edit-form-heading{
  font-size: 60px;
  padding: 30px 0px 30px 0px;
  width: 100%;
  text-align: center;
  position: sticky;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  top: 0px;
  background-color: #fff;
  text-transform: uppercase;
  z-index: 900;
}
.jh-edit-form-details{
  color:#999;
  padding-bottom:40px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
}
.jh-form-group{
  width:100%;
}
.jh-input-container{
  margin: 10px;
  border-bottom: 1px solid #444;
  overflow: hidden;
  /* width:100%; */
}
.jh-input-helper-text{
  font-size:12px;
  color:#999;
  padding-top:8px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.jh-form-button-container{
  padding:20px 10px 20px 10px;
  display:flex;
  flex-direction: row;
}
.jh-form-button-container>div{
  padding:10px;
}
.jh-create-resume-form{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width:768px){
  .jh-edit-form{
    width:90%;
  }
  .jh-edit-form-heading{
    font-size: 40px;
  }
  .jh-sticky-form-subsection{
    top:100px;
  }
  .jh-enter-form{
    width:80%;
  }
}
