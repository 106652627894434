a,a:hover{
  text-decoration: none;
  color:inherit;
}
textarea, input, button { outline: none; }
html,body{
  background-color:#fff;
  user-select: none;
}
div[role=button],a,button {
    outline: none;
}
