.jh-button-container{
  padding:10px;
  border-radius: 10px;
  transition: all linear 0.2s;
  font-size:18px;
  white-space: nowrap;
  user-select: none;
  text-transform: uppercase;
}
.jh-button-container>h1{
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:400;
}
.jh-button-container:hover{
  opacity:0.7;
  transition: all linear 0.2s;
}
@media screen and (max-width:786px){
  .jh-button-container{
    font-size: 14px;
  }
}
