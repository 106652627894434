.jh-t1-container {
  font-family: 'Source Sans Pro', sans-serif;
}
.jh-t1-heading {
  text-align: center;
  padding: 0.5in;
}
.jh-t1-heading > h1 {
  font-weight: 300;
  font-size: 0.3in;
}
.jh-t1-basics-section {
  padding: 0.25in;
  text-align: center;
}
.jh-t1-basics-section > h1 {
  font-size: 0.5in;
  color: #333;
  font-weight: 900;
}
.jh-t1-basics-section > h2 {
  font-weight: 900;
  font-size: 0.2in;
  padding: 0.1in;
  color: #333;
  text-transform: uppercase;
}
.jh-t1-basics-section > h3 {
  font-weight: 400;
  font-size: 0.2in;
}
.jh-t1-basics-section > h4 {
  font-weight: 900;
  font-size: 0.2in;
  color: #333;
}
.jh-t1-skills-section {
}
.jh-t1-skills-flex {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.jh-t1-edit-section {
  border: none;
  box-sizing: border-box;
  transition: all linear 0.2s;
  background-color: none;
}
.jh-t1-edit-sectiontrue {
  width: 100%;
}
.jh-t1-edit-section {
  width: 100%;
}
@media screen {
  .jh-t1-edit-section {
    background: none;
    transition: all linear 0.2s;
  }
  .jh-t1-edit-section:hover {
    background: rgba(255, 94, 90, 1);
    transition: all linear 0.2s;
    cursor: pointer;
    background: linear-gradient(
      -45deg,
      #ee7752,
      #ee7752,
      #ee7752,
      #e73c7e,
      #23a6d5,
      #23d5ab,
      #23d5ab,
      #23d5ab
    );
    background-size: 400% 400%;
    animation: Gradient 8s ease infinite;
  }
  .jh-t1-edit-section:hover::before {
    content: 'CLICK TO EDIT SECTION';
    position: relative;
    left: 6px;
    top: 2px;
    float: left;
    color: #fff;
    z-index: 10;
    font-weight: 900;
    transition: all linear 0.2s;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* block */
.jh-t1-block {
  /* width:100%; */
  padding: 0.25in;
  text-align: left;
}
.jh-t1-block > h1 {
  background-color: #333;
  font-size: 0.2in;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0.14in;
  color: #ffffff;
  margin-bottom: 0.1in;
}
.jh-t1-block > ul {
  text-transform: capitalize;
  list-style-type: disc;
  padding-left: 0.33in;
  font-size: 0.17in;
  font-weight: 400;
}
.jh-t1-job-details {
}
.jh-t1-job-details > h3 {
  font-size: 0.15in;
  font-weight: 300;
  padding-left: 0.14in;
  margin-bottom: 0.04in;
  color: #333;
}
.jh-t1-job-details > h4 {
  font-size: 0.15in;
  padding-left: 0.14in;
  font-weight: 900;
  color: #333;
}
